import React, { useEffect } from "react";

import LoginForm from "./LoginForm";
import ConfirmForm from "./ConfirmForm";

import { useStore } from "../../context/state";

import Loading from "commons-ui/core/Loading";

export const Page = ({ type, loading, options, onCheck }) => {
  const { dispatch, apiPath } = useStore();

  useEffect(() => {
    onCheck();
  }, []);

  if (loading) {
    return (
      <div className="container background-wrapper">
        <div
          className="paper-box u-marginTop100 u-padding25 u-flexCenter u-justifyContentCenter"
          style={{ minHeight: "calc(100vh - 60px)" }}
        >
          <Loading color="#50019f" />
        </div>
      </div>
    );
  }

  return (
    <div className="container background-wrapper">
      <div
        className="paper-box u-paddingTop100"
        style={{ minHeight: "calc(100vh - 60px)" }}
      >
        {loading ? (
          <Loading color="#50019f" />
        ) : type === "confirmation" ? (
          <ConfirmForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onSuccess={() => dispatch("/account")}
          />
        ) : (
          <LoginForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onSuccess={() => dispatch("/account")}
          />
        )}
      </div>
    </div>
  );
};
